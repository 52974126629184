import './App.css';
import Navbar from './Components/Navbar'
import Banner from './Components/Banner';
import OurClients from './Components/OurClients';
import Templates from './Components/Templates';
import Cta from './Components/Cta';
import Process from './Components/Process';
import ResumeTailored from './Components/ResumeTailored';
import Ats from './Components/Ats';
import WhyChoose from './Components/WhyChoose';
import Testimonial from './Components/Testimonial';
import Footer from './Components/Footer';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <Banner/>
      <OurClients/>
      <Templates/>
      <Cta/>
      <Process/>
      <ResumeTailored/>
      <Ats/>
      <WhyChoose/>
      <Testimonial/>
      <Footer/>
    </div>
  );
}

export default App;
